<template>
  <div class="statefulSetList">
    <div class="reminder">有状态 <span>statefulSet</span></div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        名称：
        <a-input
          placeholder="请输入名称"
          v-model.trim="name"
          @pressEnter="query()"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query()" icon="search">查询</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.name"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <span slot="label" slot-scope="text, record">
        <template v-for="(value, key) in record.label">
          <a-tag style="margin-top: 5px"> {{ key }}:{{ value }} </a-tag>
        </template>
      </span>
      <span slot="replicas" slot-scope="text, record">
        {{ record.availableReplicas }}/{{ record.replicas }}
      </span>
      <span slot="name" slot-scope="text, record">
        <a-tooltip placement="right">
          <template slot="title">
            <div v-for="item in record.images">
              {{ item }}
            </div>
          </template>
          {{ text }}
        </a-tooltip>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button type="link" @click="flexible(record)">伸缩</a-button>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="redeploy(record)"
        >
          <template slot="title">确认是否重新部署{{ record.name }}</template>
          <a-button type="link">重新部署</a-button>
        </a-popconfirm>
        <a-divider type="vertical" />
        <a-button type="link" @click="deploymentVersionPage(record, 1)"
          >回滚</a-button
        >
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <a-popconfirm
      placement="right"
      okText="确认"
      cancelText="取消"
      @confirm="batchDeleteClick()"
      :disabled="selectedRowKeys.length == 0"
    >
      <template slot="title">确认是否批量删除</template>
      <a-button
        type="danger"
        icon="delete"
        :disabled="selectedRowKeys.length == 0"
        >批量删除</a-button
      >
    </a-popconfirm>

    <a-modal
      title="修改容器组数量"
      v-model="editContainerGroupNumVisible"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="数量:">
          <a-input v-model="updateData.num" placeholder="请输入数量" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="editContainerGroupNumVisible = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="editContainerGroupNumSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="title"
      v-model="deploymentVersionShow"
      :maskClosable="false"
      width="1000px"
    >
      <div class="searchCondition">
        <div class="searchConditionItem">
          版本：
          <a-input
            placeholder="请输入版本"
            v-model.trim="searchGitCommitId"
            @pressEnter="deploymentVersionPage(null, 1)"
            @change="allowClearDeploymentVersionChange"
            allowClear
          />
        </div>
        <div class="searchButton">
          <a-button
            type="primary"
            @click="deploymentVersionPage(null, 1)"
            icon="search"
            >查询</a-button
          >
        </div>
      </div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(deploymentVersionTableColumns)"
        :columns="deploymentVersionTableColumns"
        :dataSource="deploymentVersionTableDataSource"
        :pagination="deploymentVersionTablePagination"
        :loading="deploymentVersionLoadingTable"
        size="small"
        :rowKey="(record) => record.id"
      >
        <span slot="action" slot-scope="text, record">
          <a-button type="link" @click="detail(record)">详情</a-button>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="left"
            okText="确认"
            cancelText="取消"
            @confirm="rollback(record)"
            :disabled="
              record.gitCommitId == gitCommitId ||
              record.gitCommitId == 'UNKNOWN'
            "
          >
            <template slot="title"
              >确认是否回滚至当前版本{{ record.title }}</template
            >
            <a-button
              type="link"
              :disabled="
                record.gitCommitId == gitCommitId ||
                record.gitCommitId == 'UNKNOWN'
              "
            >
              回滚
            </a-button>
          </a-popconfirm>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="deploymentVersionShow = false"
            >关闭</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="详情"
      v-model="logVisible"
      :maskClosable="false"
      width="800px"
      :footer="false"
    >
      <div style="height: 700px">
        <codemirror
          v-model="detailJson"
          :options="options"
          class="code"
        ></codemirror>
      </div>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/k8s.js";

export default {
  name: "statefulSetList",
  props: ["namespace"],
  data() {
    return {
      name: "",
      tableColumns: [
        {
          title: "名称",
          dataIndex: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "命名空间",
          dataIndex: "namespace",
          width: 100,
        },
        {
          title: "标签",
          dataIndex: "label",
          scopedSlots: { customRender: "label" },
          width: 200,
        },
        {
          title: "容器组数量",
          dataIndex: "replicas",
          scopedSlots: { customRender: "replicas" },
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 200,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      loadingTable: false,
      updateData: {},
      editContainerGroupNumVisible: false,
      deploymentVersionName: "",
      gitCommitId: "",
      searchGitCommitId: "",
      deploymentVersionLoadingTable: false,
      deploymentVersionTableDataSource: [],
      deploymentVersionTableColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 100,
        },
        {
          title: "标题",
          dataIndex: "title",
        },
        {
          title: "版本",
          dataIndex: "gitCommitId",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 100,
        },
      ],
      deploymentVersionTablePagination: {},
      deploymentVersionShow: false,
      title: "",
      logVisible: false,
      detailJson: "",
      options: {
        mode: { name: "javascript", json: true },
        readOnly: true,
        lineNumbers: true,
      },
      selectedRowKeys: [],
    };
  },
  watch: {
    namespace: {
      immediate: true,
      handler() {
        this.query();
      },
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query();
    },
    query() {
      let data = {
        namespace: this.namespace,
        name: this.name,
      };
      this.loadingTable = true;
      api
        .statefulSetList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            this.tableDataSource = res.data;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    flexible(val) {
      this.updateData = {
        num: val.replicas,
        namespace: val.namespace,
        name: val.name,
        type: "StatefulSet",
      };
      this.editContainerGroupNumVisible = true;
    },
    editContainerGroupNumSubmit() {
      let data = { ...this.updateData };
      api.editContainerGroupNum(data).then((res) => {
        if (res.result === 200) {
          this.editContainerGroupNumVisible = false;
          this.$message.success("修改成功");
          this.query();
        }
      });
    },
    redeploy(val) {
      let data = {
        name: val.name,
      };
      api.redeployment(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("重新部署成功");
          this.query();
        }
      });
    },
    allowClearDeploymentVersionChange(e) {
      if (e.target.value) {
        return;
      }
      this.deploymentVersionPage(null, 1);
    },
    deploymentVersionPage(val, index) {
      if (index) {
        this.pageNo = index;
      }
      if (val) {
        this.deploymentVersionName = val.name;
        this.gitCommitId = val.label.huiyu_git_commit_id;
        this.title = val.name + "历史版本";
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.deploymentVersionName,
        gitCommitId: this.searchGitCommitId,
      };
      this.deploymentVersionLoadingTable = true;
      api
        .deploymentVersionPage(data)
        .then((res) => {
          this.deploymentVersionLoadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item) => {
              item.content = JSON.parse(item.content);
            });
            this.deploymentVersionTableDataSource = list;
            this.deploymentVersionTablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
            this.deploymentVersionShow = true;
          }
        })
        .catch((err) => {
          this.deploymentVersionLoadingTable = false;
        });
    },
    changePageItem(index) {
      this.deploymentVersionPage(null, index);
    },
    rollback(val) {
      let data = {
        id: val.id,
      };
      api.statefulSetRollBack(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("回滚成功");
          this.deploymentVersionShow = false;
          this.query();
        }
      });
    },
    detail(val) {
      let data = val.content;
      this.detailJson = JSON.stringify(data, null, 4).toString();
      this.logVisible = true;
    },
    deleteClick(val) {
      let data = {
        name: val.name,
        namespace: val.namespace,
      };
      api.deleteStatefulSet(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
    batchDeleteClick() {
      let arr = [];
      this.selectedRowKeys.forEach((name) => {
        this.tableDataSource.forEach((item) => {
          if (item.name == name) {
            arr.push(item);
          }
        });
      });
      this.$axios
        .all(
          arr.map((item) => {
            let data = {
              name: item.name,
              namespace: item.namespace,
            };
            return api.deleteStatefulSet(data);
          })
        )
        .then((resArr) => {
          this.$message.success("删除成功");
          this.query();
        });
    },
  },
};
</script>

<style lang="scss">
.code {
  height: 100%;
  .CodeMirror {
    height: 100% !important;
  }
}
</style>
