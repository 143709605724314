import http from "./axios";

// 查询分页列表
export const aliyunDiskInfoPage = (data) => {
  return http.get("/aliyunDiskInfo/aliyunDiskInfoPage", data);
};

// 修改告警阈值
export const editAliyunDiskInfo = (data) => {
  return http.json_post("/aliyunDiskInfo/editAliyunDiskInfo", data);
};

// 修改备注
export const editAliyunDiskRemark = (data) => {
  return http.json_post("/aliyunDiskInfo/editAliyunDiskRemark", data);
};

// 立即同步
export const nowSync = (data) => {
  return http.json_post("/aliyunDiskInfo/nowSync", data);
};
